var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-changelog-type-sidebar","visible":_vm.isUpdateChangelogTypeSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-update-changelog-type-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Modification d'un type de changelog")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),(!_vm.loadingChangelogType)?_c('validation-observer',{ref:"createFormValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"libellé du type de changelog","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Libellé du type de changelog : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Libellé du type de changelog"},model:{value:(_vm.changelogType.libelle),callback:function ($$v) {_vm.$set(_vm.changelogType, "libelle", $$v)},expression:"changelogType.libelle"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{attrs:{"name":"couleur du nouveau type de changelog","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"for":"region"}},[_vm._v("Couleur du type de changelog : "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"trim":"","placeholder":"Couleur","type":"color"},model:{value:(_vm.changelogType.couleur),callback:function ($$v) {_vm.$set(_vm.changelogType, "couleur", $$v)},expression:"changelogType.couleur"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Enregistrer ")]),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1):_vm._e()],1)]}}],null,true)}):_vm._e(),(_vm.loadingChangelogType)?_c('div',{staticClass:"text-center mt-2"},[_c('b-spinner',{attrs:{"label":"Chargement...","variant":"primary"}})],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }