













































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { ChangelogType } from "@/api/models/changelogs/changelogType";
import CreateChangelogType from "./CreateChangelogType.vue";
import EditChangelogType from "./EditChangelogType.vue";
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BAlert,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BAlert,
    BButton,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateChangelogType,
    EditChangelogType,
  },
})
export default class ListChangelogTypes extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  isAddNewChangelogTypeSidebarActive = false;
  isUpdateChangelogTypeSidebarActive = false;
  selectedChangelogTypeId = "";
  searchQuery = "";
  timeout: any = null;

  tableColumns = [{ key: "libelle", sortable: true, label: "libellé" }, { key: "actions" }];
  paginatedListChangelogTypes = new PaginatedList<ChangelogType>();

  async created() {
    await this.loadListChangelogTypes({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListChangelogTypes.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListChangelogTypes.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  async paginationChanged() {
    await this.loadListChangelogTypes({
      search: this.searchQuery,
      pageNumber: this.currentPage,
      pageSize: this.perPage,
    });
  }

  selectChangelogType(changelogTypeId: string) {
    this.$router.push({
      name: "details-changelog-type",
      params: { id: changelogTypeId },
    });
  }

  editChangelogType(changelogTypeId: any) {
    this.selectedChangelogTypeId = changelogTypeId;
    this.isUpdateChangelogTypeSidebarActive = true;
  }

  @Watch('searchQuery')
  async searchChangelogTypes() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListChangelogTypes({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        search: this.searchQuery,
      })
    }, 1000);
  }

  async loadListChangelogTypes(params: any) {
    if (!params || params?.reset) {
      this.paginatedListChangelogTypes = new PaginatedList<ChangelogType>();
    }
    await this.$http.myressif.changelogTypes
      .paginatedList(params?.search, params?.pageNumber ?? 1, params?.pageSize ?? 10)
      .then((response: PaginatedList<ChangelogType>) => {
        this.paginatedListChangelogTypes = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer ce type de changelog ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  async confirmDelete(id: string) {
    await this.$http.myressif.changelogTypes
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'un type de changelog",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-changelogTypes");
        this.loadListChangelogTypes({
          search: this.searchQuery,
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }
}
